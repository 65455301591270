import React from "react"

import { List, ListItem, Stack } from "@kiwicom/orbit-components"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Demisting the Windscreen">
    <Hero
      title="Demisting the Windscreen"
      subtitle="Best practice to prevent condensation"
    />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Text>
              The best way to prevent condensation building up on the windscreen
              is to use the following settings.
            </Text>
            <List>
              <ListItem>
                Put the heating on at 19°C with the fan speed set to 2 bars.
              </ListItem>
              <ListItem>
                Turn on the air recirculation to help ensure air gets
                redistributed between the front and the back of the bus,
                avoiding the front staying cold and the back getting too hot.
              </ListItem>
              <ListItem>
                Use the dedicated demister system in addition when required. It
                works most effectively on Level 3, but will use more battery.
              </ListItem>
            </List>
            <Text>
              The air conditioning filters are regularly cleaned to help ensure
              the system is working effectively. If you have particular problems
              with one bus, report a defect in the usual manner so we can
              investigate.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
